import React from 'react';

/**
 * @param author
 * @param title
 * @param content
 * @returns {JSX.Element}
 * @constructor
 */
const ReviewSlide = ({ payload: { author, title, content }}) => {
  const parsed = content.text
    .split(' ')
    .map(entry => (
      content.highlighted[entry]
        ? `<strong>${content.highlighted[entry]}</strong>`
        : entry
    )).join(' ');

  return (
    <div className='review-carousel__item'>
      <div className='review-carousel__item-inner'>
        <blockquote className='review-carousel__item-content'>
          <p dangerouslySetInnerHTML={{ __html: parsed }} />
        </blockquote>
        <h3 className='review-carousel__title'>
          <strong>{author}</strong>
          {' '}
          {`"${title}"`}
        </h3>
      </div>
    </div>
  );
};

export default ReviewSlide;
